<template>
  <VRow
    fill-height
    no-gutters
  >
    <VCol
      md="6"
      class="pl-3"
    >
      <Alerts
        class="mx-3"
        :errors="errors"
      />
      <VContainer>
        <VSkeletonLoader
          type="card"
          ref="gg"
          :loading="isContentLoading"
        >
          <VRow class="pt-3">
            <VCol cols="12">
              <VTextField
                v-model="form.title"
                clearable
                label="Report Name"
                class="report-name"
                placeholder="Enter report title..."
                :error="$v.form.title.$error"
                @input="$v.form.title.$touch()"
                @blur="$v.$touch()"
              />
            </VCol>
            <VCol
              align="center"
              class="pl-5 pr-8 pt-9"
            >
              <component
                v-if="resolvedActiveChart.type != null && isSupportedChart(resolvedActiveChart.type)"
                width="100%"
                height="250px"
                :is="chartComponent"
                :ref="normalizeChartRef(chartComponent)"
                :config="editChartSettings"
                :chart="chart"
                :report-data="form.reporting_data"
              />
            </VCol>
          </VRow>
        </VSkeletonLoader>
      </VContainer>
    </VCol>
    <VCol
      md="6"
      class="grey lighten-4 settings-area pr-3"
    >
      <VSkeletonLoader
        min-height="500px"
        style="min-height: 555px"
        type="list-item, divider, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
        :loading="isContentLoading"
      >
        <VTabs
          grow
        >
          <div
            class="d-flex"
            style="width: 100%; height: 100%; background-color: #efefef;"
            >
            <VRow>
              <VCol
                cols="6"
                class="py-0"
              >
                <VTab
                    style="background-color: #efefef;"
                    active-class="active-tab"
                >
                  <VContainer
                      class="configure-chart-section pa-0"
                      style="background-color: #efefef;"
                  >
                    <VRow style="height: 100%">
                      <VCol
                          cols="3"
                          class="d-flex py-0 chart-type-header items-center"
                      >
                        <VSubheader
                            class="my-auto font-bold"
                        >
                          <strong>Chart Type:</strong>
                        </VSubheader>
                      </VCol>
                      <VCol
                          cols="8"
                          class="d-flex py-0 align-center"
                      >
                        <VSubheader>
                          <span
                              class="font-weight-bold"
                              style="color: rgba(0, 0, 0, 0.45)"
                          >{{ form.chart_type }}</span>
                        </VSubheader>
                      </VCol>
                    </VRow>
                  </VContainer>
                </VTab>
              </VCol>
              <VCol
                cols="6"
                class="py-0"
>
                <VTab
                  active-class="active-tab"
                  style="background-color: #efefef;"
                  >
                  <VContainer
                    class="configure-chart-section pa-0"
                    style="background-color: #efefef;"
                  >
                    <VRow style="height: 100%">
                    <VCol
                        cols="3"
                        class="d-flex py-0 items-center"
                    >
                      <VSubheader
                          class="my-auto font-bold"
                      >
                        <strong>Filter</strong>
                      </VSubheader>
                    </VCol>
                  </VRow>
                  </VContainer>
                </VTab>
              </VCol>
            </VRow>
          </div>

          <VTabItem>
            <VContainer
              class="configure-chart-section"
              style="background-color: #ececec"
            >
              <VRow>
                <VCol cols="12 py-0">
                  Data Source
                </VCol>
                <VCol
                  grow
                  class="d-flex"
                >
                  <VAutocomplete
                    solo
                    class="chart-type"
                    label="Select"
                    item-text="name"
                    item-value="id"
                    :items="availableModels"
                    :value="form.data_model_id"
                    @change="selectModel"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol
                  cols="12"
                  md="6"
                  class="pt-0 pb-0"
                >
                  <VRow>
                    <VCol cols="12 py-0">
                      Metric
                    </VCol>
                    <VCol
                      grow
                      class="d-flex"
                    >
                      <VAutocomplete
                        v-model="form.data_model_metric"
                        solo
                        class="chart-type"
                        label="Select"
                        no-data-text="Select a data source"
                        :items="availableMetrics"
                        :readonly="data_model_solo_metric"
                        :background-color="$v.form.data_model_metric.$error ? 'error lighten-5' : '#ffffff'"
                        :error="$v.form.data_model_metric.$error"
                        @input="$v.form.data_model_metric.$touch()"
                        @blur="$v.$touch()"
                        @change="metricSelected"
                      />
                    </VCol>
                  </VRow>
                </VCol>
                <VCol
                  cols="12"
                  md="6"
                  class="pt-0 pb-0"
                >
                  <VRow>
                    <VCol cols="12 py-0">
                      Category
                    </VCol>
                    <VCol
                      grow
                      class="d-flex"
                    >
                      <VAutocomplete
                        v-model="form.data_model_category"
                        solo
                        class="chart-type"
                        label="Select"
                        no-data-text="Select a data source"
                        :items="availableCategories"
                        :background-color="$v.form.data_model_category.$error ? 'error lighten-5' : '#ffffff'"
                        :error="$v.form.data_model_category.$error"
                        @input="$v.form.data_model_category.$touch()"
                        @blur="$v.$touch()"
                      />
                    </VCol>
                  </VRow>
                </VCol>
              </VRow>
              <VSkeletonLoader
                v-if="data_model.forms && data_model.forms.length > 0"
                :loading="false"
              >
                <VRow>
                  <VCol
                      cols="12"
                      md="6"
                      class="pt-0 pb-0"
                  >
                    <VRow>
                      <VCol cols="12 py-0">
                        Forms
                      </VCol>
                      <VCol
                          grow
                          class="d-flex"
                      >
                        <VAutocomplete
                            v-model="form.data_model_form_id"
                            solo
                            class="chart-type"
                            label="Select"
                            no-data-text="Select a data source"
                            :items="availableForms"
                            :multiple="data_model_metric ? data_model_metric.allowMultiple : false"
                            item-text="title"
                            item-value="id"
                            :background-color="$v.form.data_model_metric.$error ? 'error lighten-5' : '#ffffff'"
                            :error="$v.form.data_model_metric.$error"
                            @input="$v.form.data_model_metric.$touch()"
                            @blur="$v.$touch()"
                            @change="dataModelFormSelected"
                        />
                      </VCol>
                    </VRow>
                  </VCol>
                  <VCol
                      cols="12"
                      md="6"
                      class="pt-0 pb-0"
                      v-if="data_model_metric && data_model_metric.function === 'metadata'"
                  >
                    <VRow>
                      <VCol cols="12 py-0">
                        Responses
                      </VCol>
                      <VCol
                          grow
                          class="d-flex"
                      >
                        <VAutocomplete
                            v-model="form.data_model_form_metadata_id"
                            solo
                            class="chart-type"
                            label="Select"
                            no-data-text="Select a data source"
                            :items="availableMetadata"
                            item-text="title"
                            item-value="value"
                            :background-color="$v.form.data_model_category.$error ? 'error lighten-5' : '#ffffff'"
                            :error="$v.form.data_model_category.$error"
                            @input="$v.form.data_model_category.$touch()"
                            @blur="$v.$touch()"
                        />
                      </VCol>
                    </VRow>
                  </VCol>
                </VRow>
              </VSkeletonLoader>
            </VContainer>
            <component
              v-if="resolvedActiveChart.type != null && isSupportedChart(resolvedActiveChart.type)"
              v-model="form.config"
              :is="chartComponentSettings"
              :ref="normalizeChartRef(chartComponentSettings)"
              :form="$v"
              :default-settings="editChartSettings"
              @setSettings="onSetSettings"
              @reRenderChart="onReRenderChart"
              style="background-color: #ececec"
            />
            <VCol
              v-else
              align="center"
            >
              <VAlert>
                No settings available!
              </VAlert>
            </VCol>
          </VTabItem>
          <VTabItem
            class="pl-4 pr-2"
            style="background-color: #ebebeb;"
          >
            <ConfigureChartFilters
                v-model="form.data_model_filters"
                :filters="availableFilters"
                :key="chartFilterSelectRef"
            />
            <!--        <VAutocomplete-->
            <!--            solo-->
            <!--            class="chart-type"-->
            <!--            label="Select"-->
            <!--            :items="availableFilters"-->
            <!--            :value="form.data_model_filter"-->
            <!--            :background-color="$v.data_model.$error ? 'error lighten-5' : '#ffffff'"-->
            <!--            :error="$v.data_model.$error"-->
            <!--            @input="$v.data_model.$touch()"-->
            <!--            @blur="$v.$touch()"-->
            <!--            @change="selectFilter"-->
            <!--        />-->
          </VTabItem>
        </VTabs>
      </VSkeletonLoader>
    </VCol>
  </VRow>
</template>

<script>
import FormMixin from "@/mixins/Form";
import SupportsCharts from "@/mixins/SupportsCharts";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex"
import Alerts from "../Elements/Alerts.vue";
import ConfigureChartFilters from '@/components/Reports/ConfigureChartFilters';

export default {
  name: "ConfigureChartSelection",
  mixins: [FormMixin, SupportsCharts],
  props: {
    chartType: {
      type: Object,
      required: true
    },
    chart: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      options: {
        available_stats: [],
        available_reports: [],
        models: {}
      },
      data_model: {
        metrics: [],
        categories: [],
        filters: [],
      },
      data_model_form: null,
      data_model_metric: null,
      data_model_solo_metric: false,
      form: {},
      defaultForm: {
        team_id: null,
        title: null,
        user_id: null,
        is_active: false,
        data_model_id: null,
        data_model_metric: null,
        data_model_category: null,
        data_model_filters: [],
        chart_type: null,
        chart_type_id: null,
        config: {},
        pinned_to_main_dashboard: false,
        is_team_available: false,
        deleted_at: null,
        delta_time: null,
        delta_time_type: null,
      },
      isContentLoading: true,
      chartFilterSelectRef: 'filterSelection'
    };
  },
  validations: {
    form: {
      title: {
        required,
      },
      chart_type: {
        required,
      },
      chart_type_id: {
        required,
      },
      data_model_id: {
        required
      },
      data_model_metric: {
        required
      },
      data_model_category: {
        required
      }
    },
  },
  watch: {
    isFormReady: function (newVal, oldVal) {
      newVal ? this.emitReady() : this.emitNotReady();
    },
    "activity.isFormLoading": function (newVal, oldVal) {
      this.$emit("loading", newVal);
    },
    "activeForm.type": function (newVal, oldVal) {},
    "form.id": function (newVal, oldVal) {
      const dataModelId = this.form.data_model_id;
      this.data_model = this.availableModels.find(model => model.id === dataModelId);
    }
  },
  computed: {
    ...mapGetters({
      getFilters: "reporting/filters",
    }),
    editChartSettings() {
      return Object.keys(this.form.config).length > 0
        ? this.form.config
        : this.getDefaultConfigForRef(this.resolvedActiveChart.type);
    },
    availableForms() {
      return this.data_model.forms
    },
    availableMetadata() {
      return !this.data_model_form
        ? []
        : this.data_model_form.metadata.map( (item) => {
          return { title: item.name, value: item.id}
        })
    },
    availableMetrics() {
      if (!this.data_model) {
        return [];
      }

      let availableMetrics = [];
      Object.keys(this.data_model.metrics).forEach((metric) => {
        availableMetrics.push({
          text: this.data_model.metrics[metric].label,
          value: metric
        })
      })

      return availableMetrics;
    },
    availableCategories() {
      if (!this.data_model) {
        return [];
      }

      let availableCategories = [];
      this.data_model.categories.forEach((category) => {
        availableCategories.push({
          text: category,
          value: category
        });
      });

      return availableCategories;
    },
    availableFilters () {
      if (!this.data_model.filters) {
        return [];
      }

      let availableFilters = {};
      this.data_model.filters.forEach((filter) => {
        // availableFilters.push({
        //   text: filter.name,
        //   value: filter
        // })
        availableFilters[filter.id] = filter;
      })
      return availableFilters;
    },
    availableStats() {
      const availableStats = [];
      Object.keys(this.options.available_stats).reduce(
        (availableStats, group) => {
          availableStats.push({
            header: group,
          });
          availableStats.push(
            ...Object.values(this.options.available_stats[group])
          );
          return availableStats;
        },
        availableStats
      );
      return availableStats;
    },
    availableReports() {
      const availableReports = [];
      Object.keys(this.options.available_reports).reduce(
        (availableReports, group) => {
          availableReports.push({
            header: group,
          });
          availableReports.push(
            ...Object.values(this.options.available_reports[group])
          );
          return availableReports;
        },
        availableReports
      );
      return availableReports;
    },
    availableModels() {
      const availableModels = [];
      Object.keys(this.options.models).reduce(
        (availableModels, group) => {
          availableModels.push({
            header: group,
          });
          availableModels.push(
            ...Object.values(this.options.models[group])
          );
          return availableModels;
        },
        availableModels
      );
      return availableModels;
    }
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "reporting/getFormConfig",
      doSave: "reporting/save",
      doGetOne: "reporting/getOne",
      doSaveDashboardItem: "reportDashboardItem/save",
    }),
    loadFormConfig() {
      if (this.canGetFormConfig && this.onGetFormConfig instanceof Function) {
        this.$log.debug(
          "[Form Mixin]: There is a form config getter. Getting form config",
          this.onGetFormConfig
        );
        this.$emit("startOptionsLoading");
        this.onGetFormConfig()
          .then((result) => {
            result.models = Object.groupBy(result.models, ({ model_category }) => model_category)
            this.options = { ...this.options, ...result };
            return result;
          })
          .finally(() => {
            this.$emit("doneOptionsLoading");
            this.emitReady();
            this.emitLoadingDone();
            if (this.form.data_model_id) {
              this.selectModel(this.form.data_model_id)
            }
            if(this.data_model.forms) {
              this.dataModelFormSelected(this.form.data_model_form_id);
            }
            if(this.chart.config) {
              this.form.data_model_form_metadata_id = this.chart.config.data_model_form_metadata_id ?? null;
            }

            if (this.chart.filter_data) {
              this.form.data_model_filters = this.chart.filter_data ?? null;
            }
            this.isContentLoading = false;
          });
      }

      if (this.formConfig instanceof Object) {
        this.options = { ...this.options, ...this.formConfig };
      }
    },
    dataModelFormSelected(value) {
      this.data_model_form = this.availableForms.find(item => item.id === value)
    },
    metricSelected(value) {
      console.log('Metric selected', value);
      this.data_model_metric = this.data_model.metrics[value];
    },
    selectModel(value) {
      const selectedModel = this.availableModels.find(model => model.id === value);
      this.data_model = selectedModel;
      this.form.data_model_id = selectedModel.id;
      // To this to force component re-render
      this.chartFilterSelectRef += 1;

      let metricNames = Object.keys(selectedModel.metrics);
      if (metricNames.length === 1) {
        this.data_model_metric = selectedModel.metrics[metricNames[0]];
        this.form.data_model_metric = metricNames[0];
        this.data_model_solo_metric = true;
      }

      if (this.chart.data_model_metric) {
        this.data_model_metric = this.data_model.metrics[this.chart.data_model_metric];
      }
    },
    selectFilter(value) {
      const selectedFilter = this.availableFilters.find(filter => filter.value.id === value.id);
      this.form.data_model_filter = selectedFilter.value.id;
      this.form.filter_data = selectedFilter.value;
    },
    onSetSettings(settings) {
      this.form.config = {
        ...settings,
        chartRef: this.resolvedActiveChart.settings,
      };
      this.form.user_id = this.$user.id;
      this.form.team_id = this.$user.team.id;
    },
    onReRenderChart(updatedSettings) {
      this.$log.info("Ready for re-render", updatedSettings);
      // TO DO: Rerender the chart
    },
    onGet(params) {
      let getOneParams = Object.assign({}, params, {
        delta_time: this.getFilters.start,
        delta_time_type: this.getFilters.start_type
      })
      const gg = this.doGetOne(getOneParams);
      return gg;
      return Promise.resolve({});
    },
    onSave() {
      if (this.resolvedActiveChart.settings) {
        this.$refs[
          this.normalizeChartRef(this.resolvedActiveChart.settings)
          ].chartSetting();
      } else {
        let settings =
          this.resolvedActiveChart.type == "List of Users" ? { cols: 3 } : {};
        this.onSetSettings(settings);
      }
      this.form.delta_time = this.getFilters.start;
      this.form.delta_time_type = this.getFilters.start_type;
      // this.form.chart_type_id = /
      return this.saveFormModel(this.doSave).then((result) => {
        this.$log.info("Saving model", result);
        this.$bus.$emit("notificationMessage", "Report saved successfully!");
        this.$emit("set:report", result);
        this.$bus.$emit("toggle:dialog")
      });
    },
    onGetFormConfig() {
      console.log('Loading form config', this.resolvedActiveChart);
      if (this.resolvedActiveChart.type) {
        let configs = this.doGetFormConfig(this.resolvedActiveChart.type);
        console.log('config loading', this.resolvedActiveChart, this.resolvedActiveChart.type);
        return configs;
      }
    },
    onGetOne(params) {
      return this.doGetOne(params)
        .then((result) => {
          console.log('WOWIWA');
          let report = result.report || result;
          this.resolvedActiveChart.type = report.chart_type;
          this.resolvedActiveChart.type_id = report.chart_type_id;
          this.resolvedActiveChart.settings = (report.config || {}).chartRef;
          // this.editChartSettings = report.config;
          this.form.id = report.id;
          this.form.title = report.title;
          this.form.chart_type = report.chart_type;
          this.form.is_pinned_to_main_dashboard =
            report.is_pinned_to_main_dashboard;
          this.isContentLoading = false;
          this.$emit("toggleSaveBtn", true);
        })
        .catch((error) => {
          this.$log.debug("Get report error!", error);
        });
    },
  },
  destroyed() {
    this.$emit("set:report", { id: null });
  },
  beforeMount() {
    if (!this.chart.id) {
      this.canGetModel = false;
    }
  },
  mounted() {
    this.$log.info("Configure Form", this.form, this.resolvedActiveChart);
    this.form.chart_type =
      this.form.chart_type || this.resolvedActiveChart.type;
    this.form.chart_type_id =
      this.chartType.id || this.resolvedActiveChart.type_id;
    this.form.title =
      this.form.title ||
      `${this.resolvedActiveChart.type} Report: ${this.$timezone.formatDateTime(
        Date.now()
      )}`;
    this.form.report_dashboard_id =
      this.form.report_dashboard_id ||
      this.id.report_dashboard_id ||
      this.dashboardId ||
      null;
    this.form.config = this.getDefaultConfigForRef(
      this.resolvedActiveChart.type
    );
    this.data_model = {
      metrics: [],
      categories: [],
      filters: []
    };
    this.form.data_model_filters = {};
    this.form.delta_time = this.getFilters.start;
    this.form.delta_time_type = this.getFilters.start_type;
    if(this.chart.config) {
      this.form.data_model_form_id = this.chart.config.data_model_form_id ?? null;
    }

    this.$bus.$on("save:report", () => {
      this.$log.info("Bus Event: Save Report", this.form);
      this.onSave();
    });

    this.$log.info("Form Configured", this.form, this.resolvedActiveChart);
    // this.$nextTick(() => {
    //   this.loadFormModel();
    // })
  },
  beforeCreate() {
    this.form.data_model_filters = [];
  },
  components: { Alerts, ConfigureChartFilters },
};
</script>
<style lang="scss">
.addNewChart {
  .v-slide-group__wrapper {
    border-bottom: 1px solid rgb(218, 218, 218) !important;
  }

  .v-tabs-slider-wrapper {
    display: none;
  }

  .configure-chart-section {
    border-bottom: none !important;
    height: 100% !important;
  }
  .configure-filters-section {
    display: flex;
    flex-flow: column;
    height: 100% !important;
    border-bottom: none !important;
    background-color: transparent !important;
  }

  .chartAreaWrapper {
    width: 100%;
    overflow: hidden;
    > .row {
      margin: 0px 12px;
    }
  }
  .v-tab {
    padding: 0px;
    height: 100% !important;
  }
  .active-tab {
    background-color: #ebebeb;
    border: 1px solid #ebebeb;

    .configure-chart-section {
      background-color: #b2b2b2;
    }
  }
  .v-tabs-bar {
    padding: 0 !important;
  }
  .v-tabs-items {
    top: 0 !important;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 500px !important;
    border: 1px solid #ebebeb;
  }
  .v-tabs-bar__content {
    background-color: white !important;
    padding: 0px !important;
    height: 100% !important;
    margin-top: 0 !important;
  }

  .v-card__title {
    flex: 0 !important;
  }
  .center-label {
    font-size: 10px;
    width: 60px;
    top: 72px;
    position: absolute;
    left: 60px;
    line-height: 15px;
  }
  .donut-label {
    font-size: 10px;
    width: 60px;
    top: 190px;
    position: absolute;
    left: 22%;
    line-height: 15px;
  }
  .active-card {
    border: 2px solid #7479db;
  }
  .v-stepper__wrapper {
    height: 100%;
  }
  .v-stepper__content {
    padding: 0px;
    height: 490px;
  }
  .v-text-field__details {
    display: none;
  }
  .v-slide-group__content .v-tabs-bar__content {
    padding: 0px;
  }
  .v-tab--active {
    border-radius: 0 !important;
  }
  .v-stepper {
    box-shadow: none;
  }
  .v-select__selection--comma {
    font-weight: 300 !important;
  }
  .chart-type > .v-input__control > .v-input__slot {
    border-bottom: 1px solid rgb(218, 218, 218) !important;
    border-radius: unset !important;
  }
  .card-actions {
    height: 40px;
  }
  .chart-title {
    font-size: 11px;
  }
  .settings-area {
    border-left: 1px solid rgb(218, 218, 218) !important;
    background-color: #f2f2f2 !important;
    min-height: 400px;
  }
  .chart-type-header {
    border-right: 1px solid #dadada;
  }

  .vue-swatches__swatch {
    //padding: 4px;

    .vue-swatches__check__wrapper{
      top: 0;
      left: 0;
    }
  }
}
</style>
